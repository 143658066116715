// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import intl from './intl/intl'; // Import the dynamically created intl object
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.render(
  <Router>
    <IntlProvider locale={intl.locale} messages={intl.messages}>
      {/* <App pca={msalInstance} /> */}
      <App />
    </IntlProvider>
  </Router>,
  document.getElementById("root") as HTMLElement
);


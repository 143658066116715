// src/components/login/Login.tsx
import React from 'react';
import { useIntl } from 'react-intl';
import { DefaultButton } from '@fluentui/react';
import { Box, Flex, Image, Provider, teamsTheme, Text } from '@fluentui/react-northstar';
import useValidateTokenExpirationCheck from '../../utils/useValidateTokenExpiration';
import { getVisitorId, getChallenge } from '../../services/authService';


const Home: React.FC = () => {
  const intlInstance = useIntl();
  useValidateTokenExpirationCheck();

  const handleLogin = () => {
    launchHostedUI();
  };

  /**
   * @description method is to lauch hosted UI login screen
   * @memberof Home
   */
  const launchHostedUI = () => {
    getVisitorId().then(
      response => {
        if (response) {
          const ws = getChallenge(response);
          const url = `${process.env.REACT_APP_HOSTED_UI}authorize?response_type=code&client_id=${process.env.REACT_APP_USERPOOL_WEBCLIENT}&redirect_uri=${process.env.REACT_APP_CALLBACK_LOGIN}&scope=openid+profile+email&code_challenge=${ws}&code_challenge_method=S256`;
          window.open(url, '_self');
        }
      }
    );
  }

  return (
      <Provider
        className='home-provider'
        theme={teamsTheme}>

        <Flex gap="gap.large" hAlign='start' className='login-container'>
          <Box className='login-left-align'>
            <Flex column gap="gap.large" hAlign='start' vAlign="center" className='left-image-container' >
              <Image src='./images/onboarding.svg' fluid className='left-image' />
              <Flex.Item align="start">
                <Flex column hAlign='start' className='left-bottom-text'>
                  <Text className='left-intro' content={intlInstance.formatMessage({ id: 'leftIntro1' })} />
                  <Text className='left-intro-second' content={intlInstance.formatMessage({ id: 'leftIntro2' })} />
                  <div className='login-footer-text'>{intlInstance.formatMessage({ id: 'footerText1' })} </div>
                </Flex>
              </Flex.Item>
            </Flex>
          </Box>
          <Box className='login-right-form'>
            <Flex column gap="gap.large" vAlign="center" className='login-right-flex'>
              <Flex.Item align="center">
                <Flex column className='login-right-content'>
                  <Flex.Item align="start" >
                    <Image src='./images/fortive-logo.svg' />
                  </Flex.Item>
                  <Flex.Item align="start" >
                    <Text content={intlInstance.formatMessage({ id: 'singinText' })} className='padding-text sso-text' />
                  </Flex.Item>
                  <Flex.Item align="start" >
                    <DefaultButton primary onClick={handleLogin} className="login-button">
                      {intlInstance.formatMessage({ id: 'login' })}
                    </DefaultButton>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </Flex>
          </Box>
        </Flex>
      </Provider>
  );
};

export default Home;

// useFetchUserAttributes.ts
import { jwtDecode, JwtPayload } from "jwt-decode";
const useFetchUserAttributes = () => {
  
    let { id_token } = getToken();
    const userAttributes = jwtDecode(id_token) as FTVJwtPayload;
    const givenName = userAttributes.given_name;
    const email = userAttributes.email;
    const familyName = userAttributes.family_name
    const fullName = userAttributes.name
    return {
      givenName,
      email,
      familyName,
      fullName
    };
  

};

export interface FTVJwtPayload extends JwtPayload {
  given_name: string,
  email: string,
  family_name: string,
  name: string
}

export const getToken = () => {
  const token = localStorage.getItem('ftvToken') as string;
  if (!!token) {
    let { id_token, access_token } = JSON.parse(token);
    return { id_token, access_token };
  }
  return {
    id_token: '',
    access_token: ''
  };
}

export default useFetchUserAttributes;

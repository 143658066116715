// src/App.tsx
import React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
// import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import Login from './pages/login/Login';
import ChatComponent from './pages/chat/Chat';
import intl from './intl/intl'; // Import the dynamically created intl object
import { IntlProvider } from 'react-intl';
import './App.css'; // Import your global styles
// import { IPublicClientApplication } from '@azure/msal-browser';
import { Routes, Route } from 'react-router-dom';
import LanguageSelection from './pages/language-selection/LanguageSelection';
import Settings from './pages/settings/Settings';
import Home from './pages/home/Home';
import Logout from './pages/logout/Logout';

initializeIcons();


function App() {
  return (
    // <MsalProvider instance={pca}>
    <IntlProvider locale={intl.locale} messages={intl.messages}>
      <div className="app-container">
        {/* <AuthenticatedTemplate>
          <ChatComponent />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login onLogin={handleLogin} />
        </UnauthenticatedTemplate> */}
        <Pages />
      </div>
    </IntlProvider>
    // </MsalProvider>
  );
}

function Pages() {

  return (
      <Routes>
          <Route path="/chat" element={<ChatComponent />} />
          <Route path="/languageselection" element={<LanguageSelection />} />
          {/* <Route path="/chatlanding" element={<ChatLanding />} /> */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<ChatComponent />} />
      </Routes>
  );
}

export default App;

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Chat,
  Avatar,
  ShorthandValue,
  Flex,
  SendIcon,
  Provider,
  teamsTheme,
  ChatItemProps,
  teamsDarkTheme,
  Box,
  Text,
} from '@fluentui/react-northstar';
import { DefaultButton, TextField } from '@fluentui/react';
// import { AuthenticatedTemplate } from '@azure/msal-react';
import LeftMenu from '../../ui-components/LeftMenu';
import useValidateTokenExpirationCheck from '../../utils/useValidateTokenExpiration';
import { sendMessageToLambda, generateGuid } from '../../services/awsService';
import { useIdleTimer } from 'react-idle-timer'
import LeftQuestionCategory from '../../ui-components/LeftQuestionCategory';
import { useIntl } from 'react-intl';

const timeout = 120_000 // 2 minutes delay
const promptBeforeIdle = 10_000

const FTVChat: React.FC = () => {
  const intlInstance = useIntl();
  const [messages, setMessages] = useState<ChatItemProps[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [waitForResponse, setWaitForResponse] = useState<boolean>(true);
  const messagesEndRef = useRef<null | HTMLUListElement>(null);
  const [question, setQuestion] = useState<boolean>(false);
  const [sharkSessionId, setSharkSessionId] = useState<string>('');
  let wsClient: any;
  // Idle time out
  const [idleState, setIdleState] = useState<string>('Active');
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setIdleState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setIdleState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setIdleState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';


  useValidateTokenExpirationCheck();
  // window.location.reload();



  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      let spinnerMessage;
      spinnerMessage = [...messages, createChatItemSpinner('Modal', '')];
      setWaitForResponse(false);
      setMessages(spinnerMessage);
      // const response = await sendMessage(newMessage, '');
      sendMessageToLambda(newMessage, '', sharkSessionId, messageCallback);
    }
  };

  const messageCallback = (response: any) => {
    const regex = /Title:\s*([^]*?)\s*Link:\s*([^\n]*[^\s.])/;

    let responseData = [];
    if (response && typeof response === 'string') {
      responseData = JSON.parse(response).messages;
    }
    let replacedStr = '';
    let linkInfo = '';

    if (responseData.length > 0) {
      responseData.map((value: any, index: number) => {
        if (value.includes('ServiceNow')) {
          linkInfo = 'You can raise a ServiceNow ticket by clicking on this link ';
        } else {
          linkInfo = 'More information can be available at ';
        }
        // Update the value
        replacedStr = value.replace(regex, `<hr/>${linkInfo}<a href="$2" target="_blank">$1</a>`);
        if (replacedStr.includes('\n.')) {
          replacedStr = replacedStr.replace(/\.$/, "");
        }
        replacedStr += '<br/>';

        const delimiter = "#";
        const pattern = /Followup: (.+)/;
        replacedStr = value.replace(/\n/g, '')
          .replace(pattern, (match: any, group: any) => {
            const items = group.split(delimiter).filter(Boolean);
            const anchorTags = items.map((item: any) => `<div class="question-sec"><a class="question-link" data-content='${escape(item)}' href="javascript:void(0)">${item}</a></div>`).join('');
            return `<br/><hr/><span class="question-title">You may want to see the following questions:</span> ${anchorTags}`;
          });

        setMessageFromCompleteResponse(replacedStr);
        return `Updated ${value}`;
      });
    } else {
      // TODO: Handle the error part
      setMessages((prevValues: any): any => {
        const indexToRemove = prevValues.findIndex((item: any) => item.className === 'ui-chat-spinner');
        if (indexToRemove !== -1) {
          // If the element is found, remove it using splice
          prevValues.splice(indexToRemove, 1);
        }
        prevValues = [...prevValues, createChatItem('Modal', 'No response.', 'no-response')];
        scrollToBottom();
        return prevValues;
      });
      setWaitForResponse(true);
      scrollToBottom();
    }
  }

  const setFollowupQuestion = (event: any) => {
    const question = event.target.dataset.content;
    handleQuestion(unescape(question));
  }

  const setMessageFromCompleteResponse = (message: any) => {
    setMessages((prevValues: any): any => {
      const indexToRemove = prevValues.findIndex((item: any) => item.className === 'ui-chat-spinner');
      if (indexToRemove !== -1) {
        // If the element is found, remove it using splice
        prevValues.splice(indexToRemove, 1);
      }
      const lastIndexToRemoveModalMessage = prevValues[prevValues.length - 1];
      if (!!lastIndexToRemoveModalMessage && lastIndexToRemoveModalMessage.className.includes('stream')) {
        prevValues.pop();
      }
      prevValues = [...prevValues, createChatItem('Modal', message, 'complete-response')];
      scrollToBottom();
      return prevValues;
    });
    setWaitForResponse(true);
    scrollToBottom();
  }

  const setMessageFromResponse = (message: any) => {
    setMessages((prevValues: any): any => {
      const indexToRemove = prevValues.findIndex((item: any) => item.className === 'ui-chat-spinner');
      if (indexToRemove !== -1) {
        // If the element is found, remove it using splice
        prevValues.splice(indexToRemove, 1);
      }
      const lastIndexToRemoveModalMessage = prevValues[prevValues.length - 1];
      if (lastIndexToRemoveModalMessage.className.includes('stream')) {
        prevValues.pop()
      }
      if (lastIndexToRemoveModalMessage.className.includes('stream') || lastIndexToRemoveModalMessage.className.includes('user')) {
        prevValues = [...prevValues, createChatItem('Modal', message, 'stream')];
      }
      scrollToBottom();
      return prevValues;
    });
    // setWaitForResponse(true);
    scrollToBottom();
  }

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    const lastChildElement: Element | null | undefined = messagesEndRef.current?.lastElementChild as Element;
    if (lastChildElement)
      lastChildElement.scrollIntoView({ behavior: "smooth" });
  }

  const handleSend = () => {
    if (newMessage.trim() !== '') {
      sessionStorage.setItem('mergedMessage', '');
      let newMessages: ChatItemProps[] = [];
      if (!newMessage.includes('#Initialization')) {
        newMessages = [...messages, createChatItem('User', newMessage, 'user')];
      }

      setMessages(newMessages);
    }
  };

  const concatMessageAndSetResponse = (wsMessage: any) => {
    let tempMessage = sessionStorage.getItem('mergedMessage') as string;
    tempMessage += wsMessage;
    sessionStorage.setItem('mergedMessage', encodeChar(tempMessage));
    setMessageFromResponse(tempMessage);
  }

  const encodeChar = (value: string) => {
    return value
      .replace(/\\u([\d\w]{4})/gi, (match, p1) => String.fromCharCode(parseInt(p1, 16)))
      .replace(/\bN\/A\b/g, '');

  }

  useEffect(() => {
    if (messages) {
      // Data has been set, call the next function
      handleSendMessage();
      setNewMessage('');
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (question && newMessage) {
      handleSend();
      setQuestion(false);
    }
  }, [question, newMessage]);

  useEffect(() => {
    const sessionId = generateGuid();
    setSharkSessionId(sessionId);

  }, []);

  useEffect(() => {
    if (!!sharkSessionId) {
      wsClient = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL + '?sessionId=' + sharkSessionId);
      // Initialize message
      handleQuestion('Hi#Initialization');
      wsClient.onmessage = (event: any) => {

        if (event.data !== '/stop/') {

          setTimeout(() => {
            concatMessageAndSetResponse(event.data);
            scrollToBottom();
          }, 50);
        } else {
          console.info('stopping streaming');
        }
      };
    }

    return () => {
      if (!!wsClient) {
        wsClient.close();
      }
    };

  }, [sharkSessionId]);

  useEffect(() => {
    sessionStorage.setItem('waitForResponse', waitForResponse.toString());
  }, [waitForResponse]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && waitForResponse) {
      handleSend();
    }
  };

  const createChatItemSpinner = (author: string, content: string): ChatItemProps => {
    return ({
      className: author === 'User' ? 'ui-chat-user' : 'ui-chat-spinner',
      contentPosition: author === 'User' ? 'end' : 'start',
      gutter: author === 'User' ? '' : <Avatar className='chat-no-image' image='./images/fortive-logo.svg' />,
      message: (
        <div className='chat-spinner'>
        </div>
      )
    })
  }

  const createChatItem = (author: string, content: string, stream: string): ChatItemProps => {
    return ({
      className: author === 'User' ? 'ui-chat-user ' + stream : 'ui-chat-modal ' + stream,
      contentPosition: author === 'User' ? 'end' : 'start',
      gutter: author === 'User' ? '' : <Avatar className='chat-no-image' image='./images/fortive-logo.svg' />,
      message: (
        author === 'User' ?
          <Chat.Message content={<Text content={<p dangerouslySetInnerHTML={{ __html: content }}></p>} />} attached='bottom' mine />
          : <Chat.Message content={<Text content={<p
            onClick={(event: any) => {
              const responseStatus = sessionStorage.getItem('waitForResponse');
              if (event.target.tagName === 'A' && event.target.className === 'question-link' && responseStatus === 'true') {
                setFollowupQuestion(event);
              }
            }}
            dangerouslySetInnerHTML={{ __html: content }}></p>} />} attached='bottom' />
      )
    })
  }

  const handleQuestion = (value: string) => {
    if (!!value && typeof value !== 'object') {
      setQuestion(true);
      setNewMessage(value);
    }
  }

  const setQuestionValue = (event: any) => {
    const responseStatus = sessionStorage.getItem('waitForResponse');
    if (responseStatus === 'true') {
      const question = event.currentTarget.dataset.content;
      handleQuestion(question);
    }
  }


  const [activeSections, setActiveSections] = useState<string[]>([]);

  const toggleSection = (sectionId: any) => {
    setActiveSections((prevActiveSections: any) => {
      if (prevActiveSections.includes(sectionId)) {
        return prevActiveSections.filter((id: any) => id !== sectionId);
      } else {
        return [...prevActiveSections, sectionId];
      }
    });
  };

  const updateTitle = (sectionId: any, listItemValue: any) => {
    // Add logic to update the section title in your state or wherever needed.
  };




  const items: ShorthandValue<ChatItemProps>[] = messages.map((message, index) => {
    return ({
      key: index.toString(),
      ...message,
    })
  });

  return (
    <>
      <Box className='top-provider'>
        <Provider theme={teamsDarkTheme}>
          <LeftMenu setQuestionValue={handleQuestion}></LeftMenu>
        </Provider>
      </Box>
      <Flex gap="gap.small" className='main-container' column padding="padding.medium" hAlign='center' vAlign='center'>
        <LeftQuestionCategory setQuestionValue={handleQuestion}></LeftQuestionCategory>
        <Flex column className='right-topcontainer'>
          <Provider
            className='chat-provider'
            theme={teamsTheme}>
            <Flex column>
              <Flex column gap="gap.small" padding="padding.medium" className='chat-container'>
                <Chat
                  className='chat-messages'
                  ref={messagesEndRef}
                  items={items}>
                </Chat>
              </Flex>
              <Flex className='chat-field' gap="gap.small" hAlign='start' padding="padding.medium">
                <TextField
                  disabled={!waitForResponse}
                  placeholder={intlInstance.formatMessage({ id: 'chatFieldText' })}
                  value={newMessage}
                  onChange={(e, value) => setNewMessage(value || '')}
                  onKeyDown={handleKeyPress}
                  className="chat-text-field"
                />
                <Button
                  disabled={!waitForResponse}
                  icon={<SendIcon />}
                  primary
                  onClick={handleSend}
                  iconOnly
                  className='send-button'
                />

              </Flex>
            </Flex>
          </Provider>
        </Flex>
      </Flex >
      <div className='footer-container'>
        <div className='footer-left'></div>
        <div className='footer-content'>{intlInstance.formatMessage({ id: 'footerText1' })} </div>
      </div>
      {open === true}
      <div
        className='modal'
        style={{
          display: open ? 'flex' : 'none'
        }}>
        <h3>{intlInstance.formatMessage({ id: 'waitModalMessage1' })}</h3>
        <p>{intlInstance.formatMessage({ id: 'waitModalMessage2' })} {remaining} {intlInstance.formatMessage({ id: 'waitModalMessage3' })}</p>
        <DefaultButton primary onClick={handleStillHere} className="login-button">
        {intlInstance.formatMessage({ id: 'waitModalButton' })}
        </DefaultButton>
      </div>
    </>
  );
};

export default FTVChat;
// validateTokenExpirationCheck.ts
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useMsal } from '@azure/msal-react';

const useValidateTokenExpirationCheck = () => {
  const navigate = useNavigate();
  // const { instance } = useMsal();
  const location = useLocation();

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        // Check if the user is authenticated
        // const isAuthenticated = instance.getAllAccounts().length > 0;
        const isAuthenticated = localStorage.getItem('ftvToken');
        const pathName = location.pathname;
        if (!!isAuthenticated && (pathName === "/" || pathName === "/home" || pathName === "/login" )) {
          // Redirect to login if not authenticated
          navigate('/chat');
          return;
        }

        if (!isAuthenticated) {
          // Redirect to login if not authenticated
          navigate('/home');
          return;
        }

        // Check token expiration
        // TODO: Need to update code for 
        // const accessTokenResponse = await instance.acquireTokenSilent({
        //   scopes: ["openid", "profile"],
        // });

        // if (!accessTokenResponse || !accessTokenResponse.accessToken) {
        //   // Clear any cached tokens
        //   instance.clearCache();
          
        //   // Redirect to login screen
        //   navigate('/login');
        // }
      } catch (error) {
        console.error('Error checking token expiration:', error);
      }
    };

    checkTokenExpiration();
  }, [navigate]);

  return {};
};

export default useValidateTokenExpirationCheck;

import {
    Flex,
    Box,
    Image,
    List,
    Text,
    Tooltip
} from '@fluentui/react-northstar';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { CommandBarButton, Stack } from '@fluentui/react';
import useFetchUserAttributes from '../utils/useFetchUserAttributes';
import { useIntl } from 'react-intl';

interface QuestionProps {
    setQuestionValue: (data: any) => void;
}

const LeftMenu: React.FC<QuestionProps> = ({ setQuestionValue }) => {
    const intlInstance = useIntl();
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const location = useLocation();
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [initials, setInitials] = useState('');
    const [isChatHovered, setIsChatHovered] = useState(false);
    const [isLogoutHovered, setIsLogoutHovered] = useState(false);
    const [menuVisiblity, setMenuVisiblity] = useState(false);
    const [selectedValue, setSelectedValue] = React.useState({} as any);

    useEffect(() => {
        setQuestionValue(selectedValue);
    }, [selectedValue]);

    const userAttributes: any = useFetchUserAttributes();


    useEffect(() => {
        setMenuListActive(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        // TODO: change it to redux
        setNameValue('name', userAttributes.fullName);
        setNameValue('email', userAttributes.email);
    }, []);

    const toggleMenu = () => {
        setMenuVisiblity(!menuVisiblity);
    }

    const setNameValue = (nameKey: string, nameValue: string) => {
        switch (nameKey) {
            case 'name':
                const fullNameString = `${nameValue}`;
                // Extract initials
                const initials = fullNameString
                    .split(', ')
                    .map(name => name.charAt(0))
                    .reverse() // Reverse the order of initials
                    .join('');
                setInitials(initials);
                setFullname(fullNameString);
                break;

            case 'email':
                const emailString = `${nameValue}`;
                setEmail(emailString);
                break;

            default:
                break;
        }
    }

    const setMenuListActive = (pathName: any) => {
        if (pathName === '/chat') {
            setSelectedIndex(0);
            setIsChatHovered(true);
        } else if (pathName === '/settings') {
            setSelectedIndex(1);
        }

    }

    const setQuestion = (event: any) => {
        const question = event.currentTarget.dataset.content;
        setSelectedValue(question);
    }

    const itemsMenuList = [
        {
            key: 'chatWindow',
            media: (
                <Image
                    src={isChatHovered ? './images/fi-message-square-hover.svg' : './images/fi-message-square.svg'}
                    avatar
                    className='menu-icon chat-image'
                />
            ),
            content: <Stack
                onMouseEnter={() => setIsChatHovered(true)}
                onMouseLeave={() => {
                    if (selectedIndex !== 0) {
                        setIsChatHovered(false);
                    }
                }}
            >
                <Link to={{ pathname: '/chat' }}>Chat Window</Link>
            </Stack>,
        },
        // {
        //     key: 'langSetting',
        //     media: (
        //         <Image
        //             src={isSettingHovered ? './images/fi-globe-hover.svg' : './images/fi-globe.svg'}
        //             avatar
        //             className='menu-icon'
        //         />
        //     ),
        //     content: <Stack
        //         onMouseEnter={() => setIsSettingHovered(true)}
        //         onMouseLeave={() => {
        //             if (selectedIndex !== 1) {
        //                 setIsSettingHovered(false);
        //             }
        //         }}
        //     >
        //         <div className='laguage-dropdown'>Language Settings</div>
        //         <div className='language-wrapper'>
        //             <ul className='language-list'>
        //                 <li>
        //                     <div className='language-left'>
        //                         <div className='language-left-one'>Engligh</div>
        //                         <div className='language-left-two'>English</div>
        //                     </div>
        //                 </li>
        //                 <li>
        //                     <div className='language-left'>
        //                         <div className='language-left-one'>French</div>
        //                         <div className='language-left-two'>French</div>
        //                     </div>
        //                 </li>
        //             </ul>
        //         </div>
        //         {/* <LanguageDropDown setLanguageValue={handleLanguage}></LanguageDropDown> */}
        //     </Stack>,
        // }
    ]

    const itemsMenuBottomList = [
        {
            key: 'profileInfo',
            className: 'profile-info',
            media: (
                <Text
                    content={initials} // Text to be displayed in the avatar
                    className='initials-text'
                />
            ),
            header: fullname,
            content: <Tooltip
                pointing
                trigger={<Text content={email} />}
                content={email}
            />,
        },
        {
            key: 'logout',
            media: (
                <Image
                    src={isLogoutHovered ? './images/fi-log-out-hover.svg' : './images/fi-log-out.svg'}
                    avatar
                    className='menu-icon'
                />
            ),
            content: (
                <Stack
                    onMouseEnter={() => setIsLogoutHovered(true)}
                    onMouseLeave={() => {
                        setIsLogoutHovered(false);
                    }}
                >
                    <Link to={{ pathname: '/logout' }}>Logout</Link>
                </Stack>
            ),
        },
        {
            key: 'version',
            content: 'v1.0',
        }
    ]

    const feedbackStyle = {
        root: {
          borderRadius: "16px",
          selectors: {
            ".ms-Icon": {
                display: "flex !important",
                height: "32px",
                alignItems: "center",
                fontSize: "16px",
                color: "#000A17"
            }
          },
        },
      };

      const onFeedback = () => {
        window.open('https://forms.office.com/r/4y59vfUvpA', '_blank');
    }

    return (
        <><Box className='top-header'>
            <Flex fill gap="gap.small" padding="padding.medium" space='between' vAlign='center'>
                <Flex className='top-logo' gap="gap.small" padding="padding.medium" hAlign='start' vAlign='center'>
                    <Image
                        src="./images/profile-top.svg"
                        className='profile-top-icon' />
                    {/* <Flex.Item align="center">
                        <Text content={intlInstance.formatMessage({ id: 'appTitle' })} />
                    </Flex.Item> */}
                </Flex>
                <Flex gap="gap.small" padding="padding.medium" hAlign='end' vAlign='center'>
                <CommandBarButton
              iconProps={{ iconName: "Bug" }}
              title={"Feedback"}
              onClick={onFeedback}
              aria-label={"Feedback"}
              styles={feedbackStyle}
              role="button"
            />
                    <Image
                        className='cursor-pointer'
                        src="./images/ham-menu.svg"
                        onClick={toggleMenu}
                    />
                </Flex>
            </Flex>
        </Box>
            <Box className={menuVisiblity ? 'left-menu show-menu' : 'left-menu'} >
                <Flex hAlign='end' vAlign='center'>
                    <Image
                        className='cursor-pointer'
                        src="./images/menu-close.svg"
                        onClick={toggleMenu}
                    />
                </Flex>
                <Box className='left-menu-list-wrapper'>
                    <List
                        className='left-menu-menu-list'
                        items={itemsMenuList}
                        selectable
                        selectedIndex={selectedIndex}
                    />
                </Box>
                <Box className='left-menu-bottom'>
                    <List
                        className='left-menu-menu-list'
                        items={itemsMenuBottomList}
                        selectable
                    />
                </Box>
            </Box></>
    )
};

export default LeftMenu;
// src/components/login/Login.tsx
import React, { useEffect } from 'react';
// import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
// import { useIntl } from 'react-intl';
// import styles from './Login.module.css';
// import intl from '../../intl/intl'; // Adjust the path accordingly
import { Loader, Provider, teamsTheme } from '@fluentui/react-northstar';
// import useValidateTokenExpirationCheck from '../../utils/useValidateTokenExpiration';
import { getVisitorId, fetchToken } from '../../services/authService';
import { useLocation, useNavigate } from 'react-router-dom';


const Login: React.FC = () => {
  // const intlInstance = useIntl();
  // const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();
  // const [tokenCode, setTokenCode] = useState<string>('');
  // useValidateTokenExpirationCheck();

  /**
   * @description method is to initi login screen
   * @memberof Login
   */
  const initiateTokenFetch = async() => {
    getVisitorId().then(
      response => {
        if (response) {
          const code  = localStorage.getItem('code') as string;
          const visitorId = response;
          getSAMLToken(code, visitorId);        

        }
      }
    );
  }

  const getSAMLToken = async(code: string, visitorId: string) => {
    const data = `grant_type=authorization_code&client_id=${process.env.REACT_APP_USERPOOL_WEBCLIENT}&code=${code}&code_verifier=${visitorId}&redirect_uri=${process.env.REACT_APP_CALLBACK_LOGIN}`;
    const token = await fetchToken(data);
    localStorage.setItem('ftvToken', JSON.stringify(token));
    navigate('/languageselection');
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code: string = params.get('code') as string;
    localStorage.setItem('code', code);
    initiateTokenFetch()
  }, []);

  return (
      <Provider
        className='login-provider'
        theme={teamsTheme}>

        <Loader size="larger" label="Loading..." labelPosition="below" />
      </Provider>
  );
};

export default Login;
